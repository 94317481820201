import React, { useEffect, useState } from "react";
import { client } from "../lib/clients";
import { FaQuoteLeft } from "react-icons/fa";

const Testimonial = () => {
  const [alltstData, setAlltst] = useState(null);
  useEffect(() => {
    client
      .fetch(
        `*[_type == "Testimonial"]{
            title,
            des,
            gender,
            msg,
            mainImage{
              asset->{
                _id,
                url
               }
             },  
    }`
      )
      .then((data) => {
        console.log(data);
        setAlltst(data);
      })
      .catch(console.error);
  }, []);
  if (!alltstData) {
    return (
      <div className=" w-full h-screen flex justify-center items-center">
        <h1>Loading......</h1>
      </div>
    );
  }
  return (
    <>
      <div className="apo-page pb-24">
        {/* Page Header*/}
        <div className="apo-page-header pb-5">
          <div className="apo-page-header-column">
            <h1 className="apo-page-title text-[60px]">Testimonial</h1>
          </div>
        </div>
        <div className="  p-2 md:p-5 mt-3 w-full items-center flex flex-wrap gap-8">
          {alltstData &&
            alltstData.map((item, index) => (
              <section
                className={`w-[80%] mx-auto dark:bg-gray-900   rounded-lg ${
                  index % 2 === 0 && "bg-[#f9fbfd] hover:shadow-md "
                }`}
                key={index}
              >
                <div className=" px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
                  <figure className=" mx-auto">
                    <FaQuoteLeft className="h-14 w-14 mx-auto mb-11 text-gray-400 dark:text-gray-600" />
                    <img
                      className="h-[110px] w-[110px]  rounded-full mx-auto"
                      src={item.mainImage.asset.url}
                      alt="client-img"
                    />
                    <blockquote>
                      <p className=" font-poppins text-[20px] font-medium text-gray-600 dark:text-white">
                        {item.msg}
                      </p>
                    </blockquote>
                    <figcaption className="flex items-center justify-center mt-6 space-x-3">
                      <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                        <div className="pr-3 font-medium text-gray-900 dark:text-white">
                          {item.title}
                        </div>
                        <span>|</span>
                        <div className="pl-3 text-lg font-light text-gray-500 dark:text-gray-400">
                          {item.des}
                        </div>
                      </div>
                    </figcaption>
                  </figure>
                </div>
              </section>
            ))}
        </div>
      </div>
    </>
  );
};

export default Testimonial;
