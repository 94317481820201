import React from "react";
import { Link } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";

const Footer = () => {
  const socialLink = (link) => {
    switch (link) {
      case "facebook":
        window.open("https://www.facebook.com/photoinfinity.ch", "_blank");
        break;
      case "insta":
        window.open(
          "https://instagram.com/photoinfinity.ch?igshid=19dn6c2ctunkh",
          "_blank"
        );
        break;

      default:
        break;
    }
  };
  const footerform = (e) => {
    e.preventDefault();
    e.target.reset();
    alert("Thanks for being awesome!.");
  };
  return (
    <>
      {/* Footer*/}
      <footer
        id="footer"
        // data-bg-img-src="images/footer_bg.png"
        className="apo-footer footerBgImg md:ml-[110px]"
      >
        <div className="container">
          <div className="apo-widget-area apo-cols-4">
            {/* Widget*/}
            <section className="apo-widget apo-widget-size-1_7x apo-contact-info-widget">
              <h2 className="apo-widget-title">Say hi!</h2>
              <ul className="apo-contact-info">
                <li>
                  <a href="mailto:info@photoinfinity.ch">
                    info@photoinfinity.ch{" "}
                  </a>
                </li>
                <li>{/* <Link to="tel:#">+91 8148657048</Link> */}</li>
              </ul>
              <p>Copyright © 2023 PhotoInfinity.</p>
            </section>
            {/* End Widget*/}
            {/* Widget*/}
            <section className="apo-widget apo-widget-size-0_65x">
              <h2 className="apo-widget-title">PhotoInfinity</h2>
              <p className=" mb-0">
                A picture is a secret about a secret, the more it tells you the
                less you know.
                <p className=" text-center">- Diane Arbus</p>
              </p>
            </section>
            {/* End Widget*/}
            {/* Widget*/}
            <section className="apo-widget apo-widget-size-0_65x">
              <h2 className="apo-widget-title">Navigation</h2>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="hidden">
                  <Link to="blog">Blog</Link>
                </li>
                <li>
                  <Link to="contact">Contact</Link>
                </li>
                <li>
                  <Link to="about">About</Link>
                </li>
                <li>
                  <Link to="testimonial">Testimonial</Link>
                </li>
              </ul>
            </section>
            {/* End Widget*/}

            {/* Widget*/}
            <section className="apo-widget">
              <h2 className="apo-widget-title">Let's Talk</h2>
              <form className="apo-oneline-form " onSubmit={footerform}>
                <input
                  type="email"
                  name="email"
                  required
                  placeholder="Email Address"
                />
                <button
                  type="submit"
                  className=" flex justify-center items-center h-[40px]"
                >
                  <HiArrowNarrowRight />
                </button>
              </form>
              <ul className="apo-hr-dotted-list">
                <li>
                  <Link onClick={() => socialLink("facebook")} to="#">
                    Facebook
                  </Link>
                </li>

                <li>
                  <Link onClick={() => socialLink("insta")} to="#">
                    Instagram
                  </Link>
                </li>
              </ul>
            </section>
            {/* End Widget*/}
          </div>
        </div>
      </footer>
      {/* End Footer*/}
    </>
  );
};

export default Footer;
