import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { client } from "../lib/clients";
import imageUrlBuilder from "@sanity/image-url";

const CustomerGallery = () => {
  const [allcusData, setAllCus] = useState(null);

  const builder = imageUrlBuilder(client);
  function urlFor(source) {
    return builder.image(source);
  }
  useEffect(() => {
    client
      .fetch(
        `*[_type == "cusGallery"]{
        title,
        slug,
        mainImage{
          asset->{
          _id,
          url
        }
      },
    }`
      )
      .then((data) => setAllCus(data))
      .catch(console.error);
  }, []);
  if (!allcusData) {
    return (
      <div className=" w-full h-screen flex justify-center items-center">
        <h1>Loading......</h1>
      </div>
    );
  }
  return (
    <>
      <div className="apo-page">
        {/* Parallax Grid */}
        <div className="apo-parallax-grid apo-portfolio-container apo-style-2 apo-portfolio-appear flex flex-wrap overflow-hidden gap-10 m-0">
          <div className="apo-page-header apo-style-3 py-12 pb-4 flex md:flex-row flex-col  justify-between items-center">
            <div className="apo-page-header-column">
              <h1 className="apo-page-title text-left text-[2.3rem] before:border-black uppercase">
                Customer Gallery
              </h1>
            </div>
            <div className="flex justify-center items-center gap-7 mr-10">
              <Link to="/gallery/weddings">
                {" "}
                <h1 className=" text-left md:text-[2.3rem] text-[1rem] before:border-black uppercase m-0 cursor-pointer bg-slate-300 rounded-lg px-7 py-2">
                  WEDDING
                </h1>{" "}
              </Link>

              <a href="/gallery/kids-world">
                <h1 className=" text-left  md:text-[2.3rem] text-[1rem] before:border-black uppercase m-0 cursor-pointer bg-slate-300 rounded-lg px-7 py-2">
                  KIDS WORLD
                </h1>
              </a>

              <Link to="/gallery/portrait">
                <h1 className=" text-left md:text-[2.3rem] text-[1rem] before:border-black uppercase m-0 cursor-pointer bg-slate-300 rounded-lg px-7 py-2">
                  PORTRAIT
                </h1>
              </Link>
            </div>
          </div>

          <div className="w-full h-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 px-4 md:px-0  gap-5 mb-11">
            {allcusData &&
              allcusData.map((item, index) => (
                <div
                  className=" flex flex-col justify-start items-center"
                  key={index}
                >
                  <div className="w-full overflow-hidden transition-all ease-in-out">
                    <Link to="#">
                      <motion.div whileHover={{ scale: 1.1 }} className=" ">
                        <img
                          src={urlFor(item.mainImage.asset.url)}
                          alt="dsdf"
                        />
                      </motion.div>
                    </Link>
                  </div>
                  <div className="flex justify-center items-center w-[100%] bg-gray-400 py-3">
                    <Link
                      to="#"
                      className=" hover:text-white font-bold my-5 text-[2rem]  decoration-transparent bg-none uppercase text-white "
                    >
                      {item.title}
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerGallery;
