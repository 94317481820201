export const bannerData = [
  {
    id: 1,
    img: "/images/banner/1.jpg",
    headinf: " ",
    sub1: " ",
    sub2: " ",
  },
  {
    id: 2,
    img: "/images/banner/5.jpg",
    headinf: " ",
    sub1: " ",
    sub2: " ",
  },
  {
    id: 3,
    img: "/images/banner/3.jpg",
    headinf: " ",
    sub1: " ",
    sub2: " ",
  },
  {
    id: 4,
    img: "/images/banner/4.jpg",
    headinf: " ",
    sub1: " ",
    sub2: " ",
  },
  {
    id: 5,
    img: "/images/banner/6.jpg",
    headinf: " ",
    sub1: " ",
    sub2: " ",
  },
  {
    id: 6,
    img: "/images/banner/7.jpg",
    headinf: " ",
    sub1: " ",
    sub2: " ",
  },
];

export const iframeMap = [
  {
    iframe:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2715.2085060186305!2d8.388569543327035!3d47.11458509922416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478ffe6852a28371%3A0x2f5450d77fd3a570!2sLuzernerstrasse%201A%2C%206037%20Root%2C%20Switzerland!5e0!3m2!1sen!2sin!4v1674106056012!5m2!1sen!2sin",
  },
];
