import React from "react";
//import { Switch, Route } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Footer from "./component/Footer";
import Header from "./component/Header";
import Home from "./page/Home";
import Gallery from "./page/Gallery";
import Blog from "./page/Blog";
import BlogDetail from "./page/BlogDetail";
import About from "./page/About";
import Contact from "./page/Contact";
import CustomerGallery from "./page/CustomerGallery";
import SingleGallery from "./page/SingleGallery";
import Testimonial from "./page/Testimonial";

const App = () => {
  return (
    <div className="flex h-auto w-screen flex-col">
      <Header />
      <div className="md:ml-[110px]">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="gallery/:slug" element={<Gallery />} />
          <Route path="customer-gallery" element={<CustomerGallery />} />
          <Route path="customer-gallery/:slug" element={<SingleGallery />} />
          <Route path="*" element={<Home />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog/:slug" element={<BlogDetail />} />
          <Route path="about" element={<About />} />
          <Route path="testimonial" element={<Testimonial />} />
          <Route path="contact" element={<Contact />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default App;
