import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./index.css";
//import { createRoot } from "react-dom/client";
import ReactDOM from "react-dom/client";
//import ReactDOM from "react-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);

// const rootElement = document.getElementById("root");
// const root = createRoot(rootElement);

// root.render(
//   <Router>
//     <App />
//   </Router>
// );
