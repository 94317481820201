import React from "react";
import { Link } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";

const HomeAbout = () => {
  return (
    <>
      {/* {about me start} */}
      <div className="apo-section apo-fullheight-section aboutBgImg">
        <div className="container">
          <blockquote className="apo-style-3">
            <p>
              I am a highly organised and motivated professional photographer
              with a wealth of experience in a range of photographic styles and
              services.
            </p>
            <footer>
              <img src="/images/painting.png" alt="" />
            </footer>
          </blockquote>

          <Link
            to="/about"
            className="apo-btn-small flex justify-center items-center uppercase gap-3 font-bold"
          >
            Learn More About me <HiArrowNarrowRight className="text-2xl" />
          </Link>
        </div>
      </div>
      {/* {about me end} */}
    </>
  );
};

export default HomeAbout;
