import React from "react";
import Slider from "../component/home/Slider";
import Collection from "../component/home/Collection";
import HomeAbout from "../component/home/HomeAbout";

const Home = () => {
  return (
    <>
      <Slider />
      <HomeAbout />
      <Collection />
    </>
  );
};

export default Home;
