import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

import { FaFacebookF } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { Link } from "react-router-dom";

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_licbn2e",
        "template_3cb5iul",
        form.current,
        "kbIzRfPloPT1C8lgt"
      )
      .then(
        (result) => {
          e.target.reset();
          alert("Thanks for being awesome!.");
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const socialLink = (link) => {
    switch (link) {
      case "facebook":
        window.open("https://www.facebook.com/photoinfinity.ch", "_blank");
        break;
      case "insta":
        window.open(
          "https://instagram.com/photoinfinity.ch?igshid=19dn6c2ctunkh",
          "_blank"
        );
        break;

      default:
        break;
    }
  };
  return (
    <>
      <div className="apo-page-dark-skin">
        <div className="apo-page">
          {/* Content Section*/}
          <div className="apo-section apo-fullheight-section apo-align-left apo-contact-sameheight-section contactBgImg">
            <div className="apo-section-blur-bg">
              <div className="apo-section-blur-bg-element " />
            </div>
            <div className="container">
              <div className="row apo-same-height-container">
                <div className="col-sm-6 apo-same-height">
                  <div className="apo-contact-info-section">
                    <div style={{ height: "430px", width: "100%" }}>
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2715.2085060186305!2d8.388569543327035!3d47.11458509922416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478ffe6852a28371%3A0x2f5450d77fd3a570!2sLuzernerstrasse%201A%2C%206037%20Root%2C%20Switzerland!5e0!3m2!1sen!2sin!4v1674106056012!5m2!1sen!2sin"
                        width="100%"
                        height="430"
                        frameBorder="0"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        aria-hidden="false"
                        tabIndex="0"
                      />
                    </div>
                    <footer className="apo-contact-footer mt-10">
                      <h5 className="apo-social-networks-title">
                        Follow Us On
                      </h5>
                      {/* Social Networks*/}
                      <ul className="apo-social-networks apo-style-2">
                        <li>
                          <Link to="#" onClick={() => socialLink("facebook")}>
                            <FaFacebookF />
                          </Link>
                        </li>
                        <li>
                          <Link to="#" onClick={() => socialLink("insta")}>
                            <BsInstagram />
                          </Link>
                        </li>
                      </ul>
                      {/* End Social Networks*/}
                    </footer>
                  </div>
                </div>
                <div className="col-sm-6 apo-same-height">
                  <h2 className=" text-[3rem] md:text-[4rem]">
                    Drop Us a Line
                  </h2>
                  <h3 className="apo-section-sub-title">
                    We'd love to hear from you. So feel free to use the online
                    form and we'll get back to you soon.
                  </h3>
                  {/* Contact Form*/}
                  <form
                    className="apo-contact-form"
                    ref={form}
                    onSubmit={sendEmail}
                  >
                    <ul>
                      <li>
                        <div className="apo-moved-label">
                          <input
                            type="text"
                            placeholder="Name"
                            required
                            name="name"
                          />

                          <span className="apo-moved-label-border" />
                        </div>
                      </li>
                      <li>
                        <div className="apo-moved-label">
                          <input
                            type="email"
                            required
                            placeholder="Email Address"
                            name="email"
                          />

                          <span className="apo-moved-label-border" />
                        </div>
                      </li>
                      <li>
                        <div className="apo-moved-label">
                          <input
                            type="text"
                            placeholder="phone number"
                            required
                            name="phone"
                          />

                          <span className="apo-moved-label-border" />
                        </div>
                      </li>
                      <li>
                        <div className="apo-moved-label">
                          <textarea
                            name="message"
                            required
                            rows={1}
                            defaultValue={""}
                            placeholder="Message"
                          />

                          <span className="apo-moved-label-border" />
                        </div>
                      </li>
                      <li>
                        <button
                          type="submit"
                          className=" uppercase apo-btn-small apo-btn-white bg-gray-500 px-5 py-2 rounded-lg"
                        >
                          Send Message
                        </button>
                      </li>
                    </ul>
                  </form>
                  {/* End Contact Form */}
                </div>
              </div>
            </div>
          </div>
          {/* End Content Section*/}
        </div>
      </div>
    </>
  );
};

export default Contact;
