import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { client } from "../lib/clients";
import imageUrlBuilder from "@sanity/image-url";
import { PortableText } from "@portabletext/react";
import { format } from "date-fns";

const BlogDetail = () => {
  const builder = imageUrlBuilder(client);
  function urlFor(source) {
    return builder.image(source);
  }
  const [oneBlogdata, setOneBlogdata] = useState(null);
  const { slug } = useParams();
  useEffect(() => {
    client
      .fetch(
        `*[slug.current == $slug]{
          title,
          imagesGallery[]{
            asset->{
              _id,
              url
             }
           }, 
           client,
           categories,
           mainImage{
            asset->{
              _id,
              url
             }
           },   
           body,         
        publishedAt,      
       }`,
        { slug }
      )
      .then((data) => setOneBlogdata(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!oneBlogdata) {
    return (
      <div className=" w-full h-screen flex justify-center items-center">
        <h1>Loading......</h1>
      </div>
    );
  }
  return (
    <>
      <>
        {/* Page Content*/}

        <div className="apo-page">
          <div
            id="apo-fixed-content-fixed-col"
            className="apo-fixed-content-fixed-col overflow-auto scrollBar "
          >
            <h2 className=" text-[4rem]">{oneBlogdata.title}</h2>
            <PortableText value={oneBlogdata.body} />

            <hr className="apo-divider-small apo-divider-thin" />
            <dl className="apo-project-details">
              <dt>Project By:</dt>
              <dd>PhotoInfinity</dd>
              <dt>Categories:</dt>
              <dd>{oneBlogdata.categories}</dd>
              <dt>Client:</dt>
              <dd>{oneBlogdata.client}</dd>
              <dt>Made In:</dt>
              <dd>
                {format(new Date(oneBlogdata.publishedAt), "dd MMMM yyyy")}
              </dd>
            </dl>
            {/* Social Networks*/}
            {/* End Social Networks*/}
          </div>
          <div className="apo-fixed-content-scroll-col h-[700px] overflow-y-scroll">
            <div className="apo-project-images">
              <img
                src={urlFor(oneBlogdata.mainImage.asset.url)}
                alt={oneBlogdata.title}
              />
              {oneBlogdata.imagesGallery.map((val, ind) => (
                <img
                  key={ind}
                  src={urlFor(val.asset.url)}
                  alt={oneBlogdata.title}
                />
              ))}
            </div>
          </div>
        </div>

        {/* End Page Content*/}
      </>
    </>
  );
};

export default BlogDetail;
