import React, { useEffect, useRef, useState } from "react";
import { CgArrowLongRight, CgArrowLongLeft } from "react-icons/cg";
import { bannerData } from "../../data/banner";

//const colors = ["#0088FE", "#00C49F", "#FFBB28"];
const delay = 6000;
const delay2 = 6000;
const Slider = () => {
  const [index, setIndex] = useState(0);
  const [anim, setAnim] = useState(5);
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === bannerData.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );
    setTimeout(() => setAnim((val) => (val === 5 ? 0 : 5)), delay2);

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className=" bg-gray-500">
      <div
        className="w-full h-auto  whitespace-nowrap "
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {bannerData &&
          bannerData.map((item, index) => (
            <div
              key={index}
              className={`  w-full h-[300px] ssm:h-[400px] md:h-screen bg-cover bg-center inline-block ${
                anim === 5
                  ? " fade-in-image zoom-in-image1"
                  : "zoom-in-image fade-in-image1"
              }`}
              style={{
                backgroundImage: `url(${item.img})`,
              }}
            >
              <div className="flex flex-col w-[50%]  justify-center items-start  ml-[10rem]">
                <div className="flex flex-col justify-between ">
                  <div className="flex flex-col ">
                    <p className="text-[15px] text-white uppercase">
                      {item.sub1}
                    </p>
                    <p
                      className={`text-[4.5rem] text-white font-abril drop-shadow-3xl ${
                        anim === 5 ? " zoom-in-image" : " "
                      }`}
                    >
                      {item.headinf}
                    </p>
                    <p className="text-[10px] text-white ">{item.sub2}</p>
                  </div>

                  <div className=" justify-between items-center w-[130px] my-14 hidden">
                    <CgArrowLongLeft className="text-white text-[5rem] cursor-pointer hover:filter-none drop-shadow-3xl" />
                    <CgArrowLongRight className="text-white text-[5rem] cursor-pointer hover:filter-none drop-shadow-3xl" />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Slider;
