import React, { useEffect, useState } from "react";
import { HiOutlineUser } from "react-icons/hi";
import { Link } from "react-router-dom";
import { client } from "../lib/clients";
import { format } from "date-fns";

const Blog = () => {
  const [allPostsData, setAllPosts] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "post"]{
        title,
        slug,
        categories,
        mainImage{
          asset->{
          _id,
          url
        }
      },
      publishedAt,
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);
  if (!allPostsData) {
    return (
      <div className=" w-full h-screen flex justify-center items-center">
        <h1>Loading......</h1>
      </div>
    );
  }
  return (
    <>
      <div className="apo-page">
        <div className="apo-page-header apo-style-2">
          <div className="apo-page-header-column">
            <h1 className="apo-page-title">Blog Masonry</h1>
            <ul className="apo-breadcrumbs">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="blog">Blog</Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="apo-section apo-has-sidebar">
          <div className="row">
            <main>
              <div className="post-list post-list_grid">
                {allPostsData &&
                  allPostsData.map((item, index) =>
                    index % 2 === 0 ? (
                      <>
                        <div key={index} className="post-list__post post">
                          <figure className="post__preview-img">
                            <Link to={item.slug.current}>
                              <img
                                src={item.mainImage.asset.url}
                                alt={item.title}
                              />
                            </Link>
                          </figure>
                          <div className="post__header">
                            <time dateTime="2022-11-16" className="post__time">
                              {format(
                                new Date(item.publishedAt),
                                "dd MMMM yyyy"
                              )}
                            </time>
                            <h2>
                              <Link to={item.slug.current}>{item.title}</Link>
                            </h2>
                          </div>
                          <div className="post__meta">
                            <Link className=" uppercase" to={item.slug.current}>
                              {item.categories}
                            </Link>
                            <span className=" cursor-pointer text-[#b4b4b4] flex justify-center items-center gap-3 underline">
                              <HiOutlineUser className="text-[2rem]" /> ADMIN
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div key={index} className="post-list__post post">
                          <figure className="post__preview-img">
                            <Link to={item.slug.current}>
                              <img
                                src={item.mainImage.asset.url}
                                alt="img description"
                              />
                            </Link>
                          </figure>
                          <div className="post__header">
                            <time dateTime="2022-11-15" className="post__time">
                              november 15, 2022
                            </time>
                            <h2>
                              <Link to={item.slug.current}>{item.title}</Link>
                            </h2>
                          </div>
                          <div className="post__meta">
                            <Link className=" uppercase" to={item.slug.current}>
                              {item.categories}
                            </Link>
                            <span className=" cursor-pointer text-[#b4b4b4] flex justify-center items-center gap-3 underline">
                              <HiOutlineUser className="text-[2rem]" /> ADMIN
                            </span>
                          </div>
                        </div>
                      </>
                    )
                  )}
                {/* Post 1 */}

                {/* Post 2 */}
              </div>
              {/* / .post-list */}
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
